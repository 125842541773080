import * as React from "react";
import Seo from "../components/Seo";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import TealBanner from "../components/TealBanner";
import TogetherSection from "../components/TogetherSection";
import MoreInfos from "../components/MoreInfos";
import GradientSection from "../components/GradientSection";
import Button from "../components/ButtonRed";
import { Row, Col, Container } from "react-bootstrap";
import VerticalPixelImage from "../components/VerticalPixelImage";
import VerticalPixelImagePocket from "../components/VerticalPixelImagePocket";
import ImageP from "../images/banner-pocket.svg";
import logoPocket from "../images/pocket-logo.svg"
import iconLiquidity from "../images/daily-liquidity-icon.svg"
import allianzIcon from "../images/allianz-icon.svg"
import minimumAmount from "../images/minimum-amount-icon.svg"
import sowosPocketPhone from "../images/sowos-pocket-phone.svg"
import iphonesWealthImage from "../images/iphones-wealth.png"

export default function SowosWealth() {

  return (

    <div id='sowosPocket'>
      <Seo
        title="Sowos Pocket: Tu dinero con intereses y liquidez diaria."
        description="Una cuenta de inversión 100% digital que te permite ver crecer tu dinero y tenerlo disponible todos los días. La opción ideal para maximizar tu fondo de emergencias y verlo crecer día con día."
        image="/sowos_pocket.png"
      />
      <Navbar />
      <TealBanner bannerSubtitle="Te presentamos:" bannerTitle="SOWOS Pocket" urlImage={ImageP} alt="icono-pocket" bannerDescription="Para esa parte de ti que quiere ver crecer su dinero día a día sin perder disponibilidad." />
      <div className="about-sowos-pocket-container">
        <Container>
          <Row className="pt-lg-4">
            <Col className="d-none d-lg-flex align-items-center" lg={2}>
              <VerticalPixelImage />
            </Col>
            <Col className="d-none d-lg-flex" lg={3}>
              <div>
                <img
                  className="sowos-pocket-phone"
                  src={sowosPocketPhone}
                  alt="Imagen con un celular con el sistema SOWOS - Pocket."
                />
              </div>
            </Col>
            <Col className="columnn-pocket-container justify-content-center" xs={12} lg={5}>
              <div className="d-flex justify-content-center justify-content-lg-start mb-4 mb-lg-0">
                <img
                  className="pocket-logo"
                  src={logoPocket}
                  alt="Imagen del logo de Pocket - Kuspit"
                />
              </div>
              <div className="d-flex justify-content-center justify-content-lg-start">
                <h2 className="about-pocket-title text-teal fw-bold my-4 my-lg-5 px-5 px-lg-0">¿Qué es SOWOS Pocket?</h2>
              </div>
              <p className="about-pocket-description text-center text-lg-start mb-lg-5">Es una cuenta de Inversión con Kuspit Casa de Bolsa. <span className="fw-bold">Ve crecer tu dinero y tenlo disponible todos los días.</span> Esta es la opción ideal para maximizar tu fondo de emergencias o tener una nómina inteligente.</p>
              <p className="text-blue-content text-primary text-center text-lg-start fw-bold mb-lg-4">¡Puedes comenzar desde $100 MXN!</p>
              <div className="d-flex d-lg-none justify-content-center mb-5">
                <img
                  className="sowos-pocket-phone"
                  src={sowosPocketPhone}
                  alt="Imagen de un celular con el sistema SOWOS - Pocket."
                />
              </div>
              <Button href={`${process.env.GATSBY_APP_URL}/sign-up`} title="ÚNETE A SOWOS" />
            </Col>
            <Col className="d-none d-lg-flex align-items-center justify-content-end pe-0" lg={2}>
              <VerticalPixelImage />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="register-container-pocket">
        <Container className="register-banner-pocket px-0 px-lg-5 py-lg-5">
          <Row className="m-0 p-0 d-lg-none">
            <Col xs={10} className="iphones-pocket-image-container d-flex d-lg-none justify-content-end mb-4 px-0">
              <img className="iphones-pocket-image" src={iphonesWealthImage} alt="Imagen de dos iphones-wealth."/>
            </Col>
            <Col className="pixel-image-container-pocket d-flex align-items-center justify-content-end pe-0" xs={2}>
              <VerticalPixelImagePocket />
            </Col>
          </Row>
          <Row className="px-4">
            <Col className="px-0 pe-lg-3" lg={{ span: 4, offset: 1 }}>
              <h2 className="register-banner-title-pocket text-center text-lg-start fw-bold mb-4">NO DEJES TU DINERO <br/>AL 0%</h2>
              <p className="register-banner-description-pocket text-center text-lg-start fw-light mb-5 mb-lg-4">
                Si 0% de rendimiento es lo que recibes en el banco,
                ¿por qué dejarlo ahí? Haz rendir mejor tu dinero con SOWOS.
              </p>
              <div className="mb-5">
                <Button title="ÚNETE A SOWOS" href={`${process.env.GATSBY_APP_URL}/sign-up`} />
              </div>
            </Col>
            <Col className="d-none d-lg-flex iphones-image-container-pocket px-0" lg={5}>
              <img className="iphones-pocket-image" src={iphonesWealthImage} alt="Imagen de dos iphones-wealth."/>
            </Col>
            <Col className="d-none d-lg-flex justify-content-center align-items-center" lg={2}>
              <VerticalPixelImagePocket />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="benefits-pocket-container">
        <Container className="px-0 px-lg-2">
          <div className="d-flex">
            <Col xs={10} lg={12} className="pe-0 ps-5 px-lg-0">
              <h1 className="benefits-pocket-title mb-4 fw-bold text-center text-danger">Beneficios de tu cuenta SOWOS Pocket</h1>
            </Col>
          </div>
          <Row>
            <Col lg={{ span: 6, offset: 3 }} className="d-flex justify-content-center px-4 px-lg-0 mb-4 mb-lg-5">
              <p className="benefits-pocket-subtitle text-center fw-normal">Es la forma
                <span className="fw-bold"> inteligente de ahorrar </span>
                para ese dinero que necesitas tener disponible diariamente, sin sacrificar y verlo crecer.</p>
            </Col>
          </Row>
          <Row className="">
            <Col className="px-4 px-lg-5 mb-4 mb-lg-0" xs={12} lg={4}>
              <div className="benefits-icon-container d-flex justify-content-center mb-4 mb-lg-5">
                <img
                  className="daily-liquidity-icon"
                  src={iconLiquidity}
                  alt="Imagen de un icono de calendario."
                />
              </div>
              <div className="d-flex justify-content-center">
                <p className="mb-lg-4 text-teal text-center fw-bold">Liquidez diaria</p>
              </div>
              <p className="benefits-pocket-description text-center mb-0 mb-lg-3">Ve tu dinero <span className="fw-bold">crecer todos los días</span> sin perder disponibilidad. Puedes retirar de lunes a viernes de 9:00 a 13 horas.</p>
            </Col>
            <Col className="px-4 px-lg-5 mb-4 mb-lg-0" xs={12} lg={4}>
              <div className="benefits-icon-container d-flex justify-content-center mb-4 mb-lg-5">
                <img
                  className="security-icon"
                  src={allianzIcon}
                  alt="Imagen de un icono de candado."
                />
              </div>
              <div className="d-flex justify-content-center">
                <p className="mb-lg-4 text-teal text-center fw-bold">Seguridad</p>
              </div>
              <p className="benefits-pocket-description text-center mb-0 mb-lg-3">Tus recursos estan custodiados en <span className="fw-bold">Kuspit Casa de Bolsa,</span> una intitucion <span className="fw-bold">supervisada y regulada por la CNBV.</span></p>
            </Col>
            <Col className="px-4 px-lg-5 mb-4 mb-lg-0" xs={12} lg={4}>
              <div className="benefits-icon-container d-flex justify-content-center mb-4 mb-lg-5">
                <img
                  className="saving-icon"
                  src={minimumAmount}
                  alt="Imagen de un icono de moneda - dinero."
                />
              </div>
              <div className="d-flex justify-content-center">
                <p className="mb-lg-4 text-teal text-center fw-bold">Ahorro Inteligente</p>
              </div>
              <p className="benefits-pocket-description text-center mb-0 mb-lg-3">Te acercamos la <span className="fw-bold">forma inteligente de administrar tus ahorros</span> para que no dejen de crecer.</p>
              <div className="d-flex justify-content-center">
                <a
                  className="benefits-link-content text-center text-primary fw-bold"
                  target="_blank"
                  href={`${process.env.GATSBY_APP_URL}/sign-up`}
                >
                  Ir a SOWOS {'>'}
                </a>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center mt-2 mt-lg-5">
              <Button title="ÚNETE A SOWOS" href={`${process.env.GATSBY_APP_URL}/sign-up`} />
            </Col>
          </Row>
        </Container>
      </div>
      <GradientSection
        title="Con SOWOS Pocket tienes todo lo que necesitas" titleBold="para generar más por tu dinero​."
        descriptionBoldFirst="Contratación 100% digital,"
        descriptionFirst="olvídate de hacer citas y traslados, con"
        descriptionBoldFirstTwo="Sowos"
        descriptionFirstTwo="contrátalo hoy mismo en menos de 10 minutos.*"
        descriptionSecond="Producto con"
        descriptionSecondBold="condiciones negociadas especialmente para ti."
        descriptionThird="Mínimos montos de apertura:"
        descriptionThirdBold="Comienzas desde $100 MXN."
        descriptionFourth="Adminístralo"
        descriptionFourthBold="directamente desde SOWOS,"
        descriptionFourthTwo="en la palma de tu mano."
        infoFirst="* Este proceso es llevado a cabo con Kuspit Casa de Bolsa, conforme a sus normativas y disposiciones vigentes. Kuspit requiere una visita presencial a partir de una inversión mensual de 3,000 UDIS (Aproximadamente $21,000 MXN)"
        infoSecond=""
      />
      <MoreInfos title="¿Aún tienes dudas?"
        descriptionFirst="Déjanos tus datos y nos pondremos en contacto contigo"
        descriptionSecond=" para compartirte información, noticias, testimoniales y"
        descriptionThird=" certificaciones directamente en tu correo."
        errorText="Por favor ingresa un correo respectando el formato ejemplo@gmail.com"
      />
      <TogetherSection title="Juntos" />
      <Footer />
    </div>
  );
}
